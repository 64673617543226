import _logger, { LOGGER_CONFIG_DEFAULT } from '../utils/logger';
import React, { useCallback, useRef } from 'react';
import type { NextPage } from 'next'
import { useRouter } from 'next/router';
import { Suspense, useEffect, useState } from 'react';
import styled from "@emotion/styled";
import Head from 'next/head'
import DynamicCard from '../widgets/DynamicCard/DynamicCard';
import { ICardAttributes } from '../widgets/CardInterface';
import { WidgetAction } from '../widgets/WidgetInterface';
import { getCardByShareId } from '../utils/apiUtils';
import { asArray, makePathString } from '../utils/commonUtils';
import { ErrorBoundary } from 'react-error-boundary';

const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

const APP_PATH = "app";

const PageContainer = styled.div`
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      overflow: visible;
      user-select: none;

      background-repeat: cover;
`;
/* background: ${LinenBackgroundColor}; */
/* background-color: ${LinenBackgroundColor}; */
/* background-image: url(${LinenBackgroundImagePath}); */

export interface ILayoutProps {
    cardId?: string;
    shareId?: string;
    page?: string;
    cardAttributes?: ICardAttributes;
    error?: { code?: string, message?: string };
}

export interface ILayoutPageConfig {
    defaultPath?: string | string[];
    getServerSideProps?: boolean | ((context: any) => Promise<any>);    // True use default, False do not implement, function
    getStaticProps?: boolean | ((context: any) => Promise<any>);   // True use default, False do not implement, function
    getStaticPaths?: () => Promise<any>; // { paths: [], fallback: boolean }
}

export default class LayoutPage {
    config: ILayoutPageConfig = {};
    public getServerSideProps?: (context: any) => Promise<any>;
    public getStaticProps?: (context: any) => Promise<any>;
    public getStaticPaths?: () => Promise<any>;

    constructor(config?: ILayoutPageConfig) {
        this.config = { ...config };

        this.getServerSideProps = typeof this.config.getServerSideProps === "function"
            ? this.config.getServerSideProps
            : (this.config.getServerSideProps === true
                ? this.getLayoutProps
                : undefined);

        this.getStaticProps = typeof this.config.getStaticProps === "function"
            ? this.config.getStaticProps
            : (this.config.getStaticProps === true
                ? this.getLayoutProps
                : undefined);

        this.getStaticPaths = this.config.getStaticPaths
    }

    private makeShortcutPagePath = (path: string | string[] | undefined) => {
        const fullPath = path ? [...asArray(path)] : asArray(this.config.defaultPath);
        const shortcut = fullPath.shift();
        const pagePath = makePathString(undefined, fullPath);
        return ({ shortcut, pagePath });
    }

    /**
     * Get the specified layout based on the path or fallback to the default
     * 
     * @param context 
     * @returns 
     */
    private getLayoutProps = async (context: any) => {
        try {
            const { shortcut, pagePath } = this.makeShortcutPagePath(context.params?.shortcut);
            // const fullPath = context.params?.shortcut ? [...asArray(context.params.shortcut)] : asArray(this.config.defaultPath);
            // const shortcut = fullPath.shift();
            // const pagePath = makePathString(undefined, fullPath);
            logger.debug(`getCardByShareId = `, shortcut, pagePath);
            const cardAttributes = await getCardByShareId(shortcut!, pagePath);
            // if (cardAttributes?.layout) {
            //   cardAttributes.layout = seedLayoutKeys(cardAttributes.layout)!;
            //   logger.log(`cardAttributes = `, JSON.stringify(cardAttributes));
            // }
            return { props: { cardAttributes } };
        } catch (err: any) {
            return { props: { error: { code: err.code, message: err.message } } };
            // return {
            //   redirect: {
            //     destination: '/app/error',
            //     permanent: false,
            //   }
        };
    }

    public LayoutPage: NextPage<ILayoutProps> = (props) => {
        const router = useRouter();
        const cardAttributesRef = useRef<ICardAttributes | undefined>(props.cardAttributes);
        const routerQueryRef = useRef<any>(router.query);
        const [layoutRev, setLayoutRev] = useState<number>(0);      // Usage: setLayoutRev( rev => (rev + 1));

        logger.debug(`### RENDER ### /[...shortcut]`, props, router);

        //
        // TODO: Confirm if we need to defer all actions that access this widget's context (e.g. useState).
        // It's safer to defer ALL actions.
        //
        const actionQueue = useRef<WidgetAction[]>([]);
        const [processActionQueue, setProcessActionQueue] = useState<{}>(); // Usage: setProcessActionQueue({});
        const actionDispatcher = useCallback((action?: WidgetAction, deferAction: boolean = false) => {
            const doAction = (action: WidgetAction) => {
                let url: string | undefined;
                switch (action?.action) {

                    case "Navigate":
                        const match = action.url.match(/^page:\/\/(.*)/);

                        if (match?.[1]) {
                            // Navigate to page

                            const curPath = makePathString(undefined, asArray(router.query.shortcut));

                            const pagePath = match?.[1];
                            url = `${curPath}${pagePath ? `/${pagePath}` : ""}`;
                            const as = url;
                            router.push(url, as, {});
                            // window.history.pushState(null, "New Page Title", url);
                        } else {
                            const match = action.url.match(/^app:\/\/(.*)/);
                            if (match?.[1]) {
                                // Navigate to page
                                url = `/${APP_PATH}/${match?.[1]}`;
                                const as = url;
                                router.push(url, as, {});
                                // window.history.pushState(null, url, `${APP_PATH}/${url}`);
                            } else {
                                // Navigate to url
                                url = action.url;
                                switch (action.target) {
                                    // case "_blank":
                                    //   if (INSTALLED) {
                                    //     window.location.href = url;
                                    //   } else {
                                    //     window.open(url, action.target || "_blank", action.features);
                                    //   }
                                    //   break;
                                    // case "_embed":
                                    //   setPopupDialog(
                                    //     <PopDialog
                                    //       src={url}
                                    //       height={"100%"}
                                    //       sliderDirection={PopDirection.FromBottom}
                                    //       visible={true}
                                    //       onExit={() => setPopupDialog(null)}
                                    //     />);
                                    //   break;
                                    case undefined:
                                    default:
                                        window.location.href = url;
                                        break;

                                }
                            }
                            // if (action.target === "_modal") {
                            //     setPopupDialog(<PopDialog src={url} height={"100%"} sliderDirection={PopDirection.FromBottom} visible={true} />);
                            // } else if (action.target === undefined) {
                            //     window.location.href = url;
                            // } else {
                            //     window.open(url, action.target || "_blank", action.features);
                            // }
                            // window.open( url, "_blank")?.focus();
                        }

                        // const pageParams = { view: urlParams.view, p1: urlParams.p1, p2: action.pagePath };
                        // loadMainContent(pageParams);
                        // window.location.href = `${hydratedVarsRef.current?.cardLocation}/${action.pagePath}`;
                        break;

                    case "SetBackground":
                        // setBackgroundNode(action.content);
                        break;

                    case "SetOverlay":
                        // setOverlayNode(action.content);
                        break;
                }
            }

            // Push new action
            action && actionQueue.current.push(action);

            // Defer or process pending queue
            if (deferAction) {
                setProcessActionQueue({});
            } else {
                // Process all pending actions
                while (actionQueue.current.length > 0) {
                    const action = actionQueue.current.shift();
                    action && doAction(action);
                }
            }
        }, [router]);

        // Process pending queueu
        useEffect(() => {
            actionDispatcher();
        }, [processActionQueue]);



        const errorFallback = useCallback(({ error, componentStack, resetErrorBoundary }: any) => {
            return (
                <div role="alert">
                    <p>Something went wrong:</p>
                    <pre>{error.message}</pre>
                    <pre>{componentStack}</pre>
                    <button onClick={() => {
                        // setLocation("/mycards");
                        resetErrorBoundary();
                    }}>Go Back</button><br /><br />
                    Or, you could ... <div style={{ width: "auto", border: "2px dashed white" }} onClick={resetErrorBoundary}>[ Try Again ]</div>

                </div>
            )
        }, []);


        if (routerQueryRef.current !== router.query) {
            logger.warn(`*** route changed ***`);
            routerQueryRef.current = router.query;

            const { shortcut, pagePath } = this.makeShortcutPagePath(router.query?.shortcut);
            // const fullPath = router.query?.shortcut ? [...asArray(router.query.shortcut)] : asArray(this.config.defaultPath);
            // const shortcut = fullPath.shift();
            // const pagePath = makePathString(undefined, fullPath);
            logger.debug(`getCardByShareId = `, shortcut, pagePath);
            getCardByShareId(shortcut!, pagePath)
                .then((cardAttributes) => {
                    cardAttributesRef.current = cardAttributes;
                    setLayoutRev(prev => {
                        return (prev + 1);
                    })
                })
        }

        //
        // Handle Error
        //
        if (props.error) return <>{props.error.code}</>

        //
        // Render Card
        //
        return (
            <ErrorBoundary FallbackComponent={errorFallback}>
                <Suspense fallback={null}>
                    {cardAttributesRef.current?.layout
                        ? <>
                            <Head>
                                <title>{cardAttributesRef.current?.layout?._title || ""}</title>
                                <meta name='description' content={"Meet Deets App"} />
                                <meta property="og:title" content={`Meet Deets`} />
                                <meta property="og:description" content={"Meet Deets App"} />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta charSet="utf-8" />
                                <link rel="icon" href="/favicon.ico" />
                                <link rel='manifest' href='/manifest.json' />
                            </Head>

                            <PageContainer>
                                <DynamicCard key={layoutRev} cardAttributes={cardAttributesRef.current!} actionDispatcher={actionDispatcher} />
                            </PageContainer>
                        </>
                        : null}
                </Suspense>
            </ErrorBoundary>
        );
    }
}