import { ICardAttributes } from "../widgets/CardInterface";
import { postQuery } from "./clientUtils";
import { minifyTLStringWhitespace } from "./commonUtils";

import _logger, { LOGGER_CONFIG_DEFAULT } from '../utils/logger';
import { ReasonPhrases } from "http-status-codes";
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

export const getCardByShareId = async (id: string, pagePath?: string) => {
    try {
        const apiEndpoint = "http://10.0.0.8:4000/api/v1"
        const shareIdArgument = `shareId: "${id}"`;
        const pagePathArgument = `${pagePath ? `, pagePath: "${pagePath}"` : ""}`;
        const reqFiles = `
            layout
            vars
            configureLayout
        `;
        const queryString = minifyTLStringWhitespace(`query {
            getCardByShareId(${shareIdArgument}${pagePathArgument}) {
                id
                isTemplate
                userId
                cardType
                cardState
                title
                memo
                createdAt
                updatedAt
                ${reqFiles}
            }
        }`);

        const resp = await postQuery(apiEndpoint, queryString);

        const theCard: ICardAttributes = resp?.data?.data?.getCardById || resp?.data?.data?.getCardByShareId;
        if (theCard) {
            if (pagePath) theCard.pagePath = pagePath;
            logger.debug(`getCard api response:`, theCard);
            return theCard;
        } else {
            const errString = ReasonPhrases.NOT_FOUND;
            logger.error(errString);
        }
    } catch (err: any) {
        logger.error(err);
        throw err;
    }

}

export const getApiErrors = ({ message, graphQLErrors, networkError }: any): string => {
    let errDetail = '';

    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }: any) => {
            errDetail += `, [GraphQL Error Message: ${message}, Locations: ${JSON.stringify(locations || {})}, Path: ${path}]`;
        });
    }

    if (networkError) {
        networkError.result?.errors?.forEach(
            ({ message, locations }: any) => {
                errDetail += `, [Network Error Message: ${message}, Locations: ${JSON.stringify(locations || {})}]`;
            });
    }

    return `[API Error] ${message}${errDetail}`;
    ;
}
